import React, { useState, useEffect, useRef } from "react";

import AmmoniteButton from "../../components/Button/button";
import { Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import AmmoniteNavigationArrow from "../../Assets/SVG/navigationArrow/navigationArrow";
import AmmoniteDownArrow from "../../Assets/SVG/downArrow";
import AmmoniteScrollArrow from "../../Assets/SVG/scrollArrow";

import AmmoniteResetIcon from "../../Assets/SVG/resetIcon/resetIcon";

import "./page.css";
import AmmoniteMenu from "../Menu/Menu";
import { useHistory } from "react-router";

function Page({
  progress,
  headerText,
  introText,
  startOver,
  backLink,
  forwardLink,
  print,
  submit,
  reset,
  isValid,
  children,
  howWeWorkedThisOutScrollable,
  howWeWorkedThisOut,
  displayValidation,
}) {
  const { push } = useHistory();
  const redoIcon = (
    <div style={{ marginTop: "4px", marginLeft: "6px", marginRight: "8px" }}>
      <AmmoniteResetIcon />
    </div>
  );
  const backIcon = (
    <div style={{ marginTop: "2px", marginRight: "4px" }}>
      <AmmoniteNavigationArrow back />
    </div>
  );
  const nextIcon = (
    <div style={{ marginTop: "2px", marginLeft: "4px" }}>
      <AmmoniteNavigationArrow />
    </div>
  );

  const [menuOpen, setMenuOpen] = useState(false);
  const [isMouseOverNext, setIsMouseOverNext] = useState(false);
  const [reRouteTo, setReRouteTo] = useState();
  const [showHowWeWorkedThisOut, setShowHowWeWorkedThisOut] = useState(false);
  const menuRef = useRef();

  const [showArrow, setShowArrow] = useState(howWeWorkedThisOutScrollable);
  let HWWTOScrollable = useRef();

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleShowHowWeWorkedThisOut = () => {
    setShowHowWeWorkedThisOut(!showHowWeWorkedThisOut);
    if (howWeWorkedThisOutScrollable) {
      setShowArrow(true);
    }
  };

  const howWeWorkedThisOutCloseHandler = () => {
    setShowHowWeWorkedThisOut(false);
  };

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      menuOpen === true
    ) {
      setMenuOpen(false);
    }
  };

  const handleCloseMenuAndReroute = (route) => {
    setReRouteTo(route);
  };

  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - Math.abs(e.target.scrollTop) ===
      e.target.clientHeight
    ) {
      setShowArrow(false);
    } else {
      setShowArrow(true);
    }
  };

  const handleScrollTo = () => {
    const y = HWWTOScrollable.current.getBoundingClientRect().bottom;
    HWWTOScrollable.current.scrollTo(0, y);
  };

  useEffect(() => {
    if (displayValidation && isMouseOverNext) {
      displayValidation(isMouseOverNext);
    }
    if (reRouteTo) {
      setMenuOpen(false);
      setTimeout(() => {
        push(reRouteTo);
      }, 220);
    }
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, [menuOpen, reRouteTo, isMouseOverNext]);

  return (
    <div className="screen">
      <Typography
        color="primary"
        variant="h4"
        align="inherit"
        fontWeight="fontWeightBold"
        className="headerContainer"
      >
        {headerText}
      </Typography>

      {introText && (
        <Typography
          align="inherit"
          fontWeight="fontWeightBold"
          className="introContainer"
        >
          {introText}
        </Typography>
      )}
      <div />

      <div className="mainContainer">{children}</div>
      {howWeWorkedThisOut && showHowWeWorkedThisOut && (
        <div className="modalScreen">
          <div
            className="howWeWorkedThisOutCopy"
            ref={HWWTOScrollable}
            onScroll={(e) => handleScroll(e)}
          >
            <div
              className="howWeWorkedThisOutCloseButton"
              onClick={howWeWorkedThisOutCloseHandler}
            >
              <div className="howWeWorkedThisOutCloseButtonText">Close</div>
              <svg
                width="1280"
                height="566"
                viewBox="0 0 1280 566"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1229.41 42.0882L1233.71 38.1214C1234.1 37.7602 1234.1 37.1762 1233.71 36.8149C1233.32 36.4536 1232.68 36.4536 1232.29 36.8149L1228 40.7816L1223.71 36.8149C1223.32 36.4536 1222.68 36.4536 1222.29 36.8149C1221.9 37.1762 1221.9 37.7602 1222.29 38.1214L1226.59 42.0882L1222.29 46.0549C1221.9 46.4162 1221.9 47.0002 1222.29 47.3614C1222.49 47.5416 1222.74 47.6322 1223 47.6322C1223.26 47.6322 1223.51 47.5416 1223.71 47.3614L1228 43.3947L1232.29 47.3614C1232.49 47.5416 1232.74 47.6322 1233 47.6322C1233.26 47.6322 1233.51 47.5416 1233.71 47.3614C1234.1 47.0002 1234.1 46.4162 1233.71 46.0549L1229.41 42.0882Z"
                  fill="#7A7A7A"
                />
              </svg>
            </div>
            <Typography
              variant="h5"
              color="primary"
              className="howWeWorkedThisOutTitle"
            >
              How we worked this out
            </Typography>
            <Typography variant="body1" className="howWeWorkedThisOutText">
              {howWeWorkedThisOut}
            </Typography>
            {showArrow && (
              <div className="arrow-wrap" onClick={handleScrollTo}>
                <AmmoniteScrollArrow />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="menuButtonContainer" onClick={handleMenuClick}>
        <div className="sectionProgressContainer">
          {progress * 8} of 8 sections
        </div>
        <FontAwesomeIcon icon={faBars} size="1x" color="#7A7A7A" />
      </div>
      <div className="buttonsBox">
        {howWeWorkedThisOut && (
          <div className="howWeWorkedThisOut">
            How we worked this out
            <div
              className={`howWeWorkedThisOutDropdownIcon ${
                showHowWeWorkedThisOut === false
                  ? "howWeWorkedThisOutIsOpen"
                  : ""
              }`}
              onClick={handleShowHowWeWorkedThisOut}
            >
              <AmmoniteDownArrow />
            </div>
          </div>
        )}
        <div className="navigationButtons">
          {startOver && (
            <div
              data-testid="reset-page-button"
              className="buttonResetContainer"
            >
              <AmmoniteButton
                buttonText={"Reset"}
                reset={reset}
                icon={redoIcon}
                borderColor="#7A7A7A"
                color="#7A7A7A"
                iconLeft
                width={120}
                height={46}
              />
            </div>
          )}
          {backLink && (
            <div data-testid="back-page-button" className="buttonBackContainer">
              <AmmoniteButton
                buttonText={"Back"}
                buttonLink={backLink}
                disableElevation
                color="secondary"
                width={120}
                height={46}
                icon={backIcon}
                iconLeft
              />
            </div>
          )}
          {forwardLink && (
            <div
              data-testid="next-page-button"
              className="buttonNextContainer"
              onMouseEnter={() => setIsMouseOverNext(true)}
              onMouseLeave={() => setIsMouseOverNext(false)}
            >
              <AmmoniteButton
                buttonText={"Next"}
                fontWeight="bold"
                buttonLink={forwardLink}
                submit={submit}
                color="primary"
                width={120}
                height={46}
                icon={nextIcon}
                iconRight
                disabled={!isValid}
              />
            </div>
          )}
          <div className="buttonBackContainer">
            {print && <AmmoniteButton buttonText={"Print"} buttonLink={"/"} />}
          </div>
        </div>
      </div>
      {
        <div
          ref={menuRef}
          className={`menuContainer ${menuOpen === true ? "isOpen" : ""}`}
        >
          <AmmoniteMenu
            handleCloseMenuAndReroute={(route) =>
              handleCloseMenuAndReroute(route)
            }
          />
        </div>
      }
    </div>
  );
}

export default Page;
