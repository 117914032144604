// Core React dependencies
import React from "react";

// Extra React dependencies
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// External libraries
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useAuth0 } from "@auth0/auth0-react";
import history from "./Utils/history";
import Loader from "react-loader-spinner";

// Component imports
import Layout from "./containers/Layout/Layout";
import Login from "./pages/Login/Login";
import About from "./pages/About/about";
import Health from "./pages/Health/health";
import Info from "./pages/Info/info";
import Goals from "./pages/Goals/goals";
import Retirement from "./pages/Retirement/retirement";
import FurtherInfo from "./pages/FurtherInfo/furtherInfo";
import GoalsReview from "./pages/GoalsReview/goalsReview";
import Summary from "./pages/Summary/summary";
import Charts from "./pages/Charts/charts";
import Actions from "./pages/Actions/actions";
import ProtectedRoute from "./containers/ProtectedRoute/protectedRoute";

import AmmoniteToast from "./components/ValidationToasts/validationToasts";

import { ToastProvider } from "react-toast-notifications";

// Utilities
import { AmmoniteContextProvider } from "./Utils/contexts/AmmoniteContext";
import { theme } from "./Utils/themes/theme";

// Styling
import "./App.css";

function App() {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loader type="Grid" color="#3bb9c4" height={80} width={80} />;
  }
  return (
    <ToastProvider components={{ Toast: AmmoniteToast }}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <AmmoniteContextProvider>
            <Layout>
              <Router history={history}>
                <Switch>
                  <ProtectedRoute exact path="/about" component={About} />
                  <Route exact path="/basicinformation" component={Info} />
                  <Route
                    exact
                    path="/yourfinancialhealthcheck"
                    component={Health}
                  />
                  <Route exact path="/chooseyourgoals" component={Goals} />
                  <Route
                    exact
                    path="/tellusaboutyourgoals"
                    component={FurtherInfo}
                  />
                  <Route
                    exact
                    path="/yourretirementspending"
                    component={Retirement}
                  />
                  <Route exact path="/savingforretirement" component={Charts} />
                  <Route
                    exact
                    path="/yourgoalsummary"
                    component={GoalsReview}
                  />
                  <Route exact path="/results" component={Actions} />
                  <Route exact path="/thankyou" component={Summary} />
                  {/* ****** */}
                  <Route path="/">
                    <Login />
                  </Route>
                </Switch>
              </Router>
            </Layout>
          </AmmoniteContextProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ToastProvider>
  );
}

export default App;
