import tenantConfig from "../tenantConfig.json";

export const getTenantConfig = function (jsonPath) {
  var result = tenantConfig;
  for (var i = 0; i < jsonPath.length; i++) {
    result = result[jsonPath[i]];
  }
  if (result.client.length < 1) {
    return result.default;
  }
  return result.client;
};
