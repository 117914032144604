// Core React dependencies
import React, { useState, useEffect, useRef, useContext } from "react";

// External libraries
import { Line, defaults } from "react-chartjs-2";
import { Typography } from "@material-ui/core";

// Component imports
import Page from "../../containers/Page/page";
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import AmmoniteInformation from "../../Assets/SVG/info";
import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";
import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteDownArrow from "../../Assets/SVG/downArrow";
import GraphHeader from "../../containers/GraphHeader/GraphHeader";

// Utilities
import { getChartsData } from "../../Utils/data/getChartsData";
import { annualSpending } from "../../Utils/data/annualSpending";
import {
  getRetirementGoalToday,
  getRetirementGoalTomorrow,
} from "../../Utils/data/getRetirementGoalAmounts";
import { getTenantConfig } from "../../Utils/data/getTenantConfig";

// Actions
import {
  setCustomerTaxRate,
  setCustomerFees,
  setRetirementAmountRequiredToday,
  setRetirementGoalAmount,
} from "../../Utils/reducers/actions";

// Styling
import "./charts.css";

function Charts() {
  const context = useContext(AmmoniteContext);
  const dispatch = context.dispatch;

  const chartEl = useRef(null);

  const [data, setData] = useState();
  const [annualRetirementSpending, setAnnualRetirementSpending] = useState(
    annualSpending(
      context.state.retirementSpending,
      context.state.retirementComforts,
      context.state.retirementLuxuries,
      context.state.customerInfo.couple
    )
  );
  const [startValue, setStartValue] = useState(0);
  const [retirementGoalTodayChart, setRetirementGoalTodayChart] = useState(
    getRetirementGoalToday(
      annualRetirementSpending,
      context.state.statePension,
      context.state.customerInfo.couple,
      context.state.pensionYield
    )
  );
  let currentYear = new Date().getFullYear();
  let yearOfBirth = new Date(
    context.state.customerInfo.customers.customer1.customerDoB
  ).getFullYear();
  let yearsToRetirement =
    context.state.customerGoals[0].goalTimeHorizon -
    (currentYear - yearOfBirth);
  if (yearsToRetirement < 0) {
    yearsToRetirement = 0;
  }
  const [retirementGoalValue, setRetirementGoalValue] = useState(
    getRetirementGoalTomorrow(
      retirementGoalTodayChart,
      context.state.cpi,
      yearsToRetirement
    )
  );
  const [netMonthlyContributions, setNetMonthlyContributions] = useState(0);
  const [netAnnualContributions, setNetAnnualContributions] = useState();
  const [showTaxRates, setShowTaxRates] = useState(true);
  const [showFees, setShowFees] = useState(false);
  const [taxRate, setTaxRate] = useState(
    context.state.customerInfo.customers.customerTaxRate
  );
  const [newFees, setNewFees] = useState(context.state.fees);
  const [cpi, setCpi] = useState(context.state.cpi);

  defaults.global.defaultFontFamily = "Arial";
  defaults.global.defaultFontColor = getTenantConfig([
    "AppSettings",
    "textPrimaryColor",
  ]);
  defaults.global.defaultFontSize = 18;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 100,
        left: 50,
        right: 50,
        bottom: 50,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      tooltips: {
        displayColors: false,
        titleFontSize: 16,
        bodyFontSize: 14,
        xPadding: 10,
        yPadding: 10,
      },
      xAxes: [
        {
          ticks: { display: true },
          gridLines: {
            display: false,
            drawBorder: false,
          },
          scaleLabel: {
            display: true,
            labelString: "Years",
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            precision: 2,
            min: 0,
            suggestedMax: 10000,
            callback: (value) => {
              return "£" + value;
            },
          },
        },
      ],
    },
  };

  const handleStartValueChange = (event) => {
    if (isNaN(parseInt(event.target.value))) {
      setStartValue("");
      return;
    }
    return setStartValue(parseInt(event.target.value), 10);
  };

  const handleNetMonthlyContributionsChange = (event) => {
    setNetAnnualContributions(event.target.value * 12);
    return setNetMonthlyContributions(parseInt(event.target.value), 10);
  };

  const handleShowTaxRates = () => {
    if (showFees === true) {
      setShowFees(false);
    }
    setShowTaxRates(!showTaxRates);
  };

  const handleShowFees = () => {
    if (showTaxRates === true) {
      setShowTaxRates(false);
    }
    setShowFees(!showFees);
  };

  const getRetirementGoalLine = () => {
    let retirementGoalLineValues = [];
    for (let i = 0; i < yearsToRetirement + 1; i++) {
      retirementGoalLineValues.push(retirementGoalTodayChart);
    }
    return retirementGoalLineValues;
  };

  const getNewData = () => {
    let retirementGoalLine = getRetirementGoalLine();
    setData(
      getChartsData(
        startValue,
        cpi,
        newFees,
        taxRate,
        context.state.cashGrowthRate,
        context.state.investedGrowthRate,
        context.state.pensionGrowthRate,
        currentYear,
        yearOfBirth,
        netAnnualContributions,
        context.state.customerGoals[0].goalTimeHorizon,
        context.state.couple,
        retirementGoalLine
      )
    );
  };

  const taxRateFilled = (
    <div className="taxRateFilledButtonCardHandlerContainer">
      <AmmoniteMenuCircleFilledTicked
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const taxRateHandler = (index) => {
    let custTaxRate = 0.2;
    if (index === 0) {
      custTaxRate = 0.2;
    } else if (index === 1) {
      custTaxRate = 0.4;
    } else if (index === 2) {
      custTaxRate = 0.45;
    }
    setTaxRate(custTaxRate);
    dispatch({
      type: setCustomerTaxRate,
      payload: { customerTaxRate: custTaxRate },
    });
  };

  const feeHandler = (fees) => {
    setNewFees(fees);
    dispatch({ type: setCustomerFees, payload: { customerFees: fees } });
  };

  const emptyBasicTaxRate = (
    <div
      className="emptyTaxButtonHandlerContainer"
      onClick={() => taxRateHandler(0)}
    >
      <AmmoniteMenuCircleEmpty
        colour={getTenantConfig(["AppSettings", "checkboxColour"])}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptyHigherTaxRate = (
    <div
      className="emptyTaxButtonHandlerContainer"
      onClick={() => taxRateHandler(1)}
    >
      <AmmoniteMenuCircleEmpty
        colour={getTenantConfig(["AppSettings", "checkboxColour"])}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptyAdditionalTaxRate = (
    <div
      className="emptyTaxButtonHandlerContainer"
      onClick={() => taxRateHandler(2)}
    >
      <AmmoniteMenuCircleEmpty
        colour={getTenantConfig(["AppSettings", "checkboxColour"])}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptyAmmoniteFees = (
    <div
      className="emptyTaxButtonHandlerContainer"
      onClick={() => feeHandler(1.25)}
    >
      <AmmoniteMenuCircleEmpty
        colour={getTenantConfig(["AppSettings", "checkboxColour"])}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const emptyIFAFees = (
    <div
      className="emptyTaxButtonHandlerContainer"
      onClick={() => feeHandler(2.5)}
    >
      <AmmoniteMenuCircleEmpty
        colour={getTenantConfig(["AppSettings", "checkboxColour"])}
        width="36px"
        height="36px"
        cx="18px"
        cy="18px"
        r="14px"
      />
    </div>
  );

  const getGMC = () => {
    let gmc =
      Math.round(
        (1 / (1 - taxRate) + Number.EPSILON) * netMonthlyContributions * 100
      ) / 100;
    if (isNaN(gmc)) {
      gmc = 0;
    }
    return gmc;
  };

  const resetChartsHandler = () => {
    setTaxRate(0.2);
    feeHandler(1.25);
    setStartValue(" ");
    setNetMonthlyContributions(" ");
    setNetAnnualContributions(" ");
  };

  useEffect(() => {
    if (retirementGoalValue) {
      dispatch({
        type: setRetirementGoalAmount,
        payload: { goalAmount: retirementGoalValue },
      });
    }
    if (retirementGoalTodayChart) {
      dispatch({
        type: setRetirementAmountRequiredToday,
        payload: { amountRequiredToday: retirementGoalTodayChart },
      });
    }
    if (
      retirementGoalValue !== "undefined" &&
      retirementGoalValue !== undefined &&
      retirementGoalValue !== "null" &&
      newFees &&
      taxRate &&
      context.state.cashGrowthRate &&
      context.state.cashGrowthRate !== "undefined" &&
      currentYear &&
      currentYear !== "undefined" &&
      yearOfBirth &&
      yearOfBirth !== "undefined"
    ) {
      getNewData();
    }
  }, [
    startValue,
    netMonthlyContributions,
    showTaxRates,
    showFees,
    currentYear,
    yearOfBirth,
    newFees,
    taxRate,
    annualRetirementSpending,
    retirementGoalTodayChart,
    retirementGoalValue,
  ]);

  return (
    <Page
      headerText={"Saving for retirement"}
      introText={
        "Enjoying the benefits of pension tax relief and keeping fees to a minimum can make a big difference to your retirement pot."
      }
      startOver
      reset={resetChartsHandler}
      backLink="/yourretirementspending"
      forwardLink="/yourgoalsummary"
      isValid={true}
      progress={0.75}
      howWeWorkedThisOutScrollable={true}
      howWeWorkedThisOut={
        <div>
          This infographic is designed to show the impact of long-term
          investment growth, tax relief, inflation and charges, and is not
          intended as personal financial advice. Please note that in reality the
          lines would go up and down over time, and we are showing a smoothed
          version for clarity. All of the data is shown in ‘today’s money’.
          <div>
            <br></br>
            On the previous screen, you determined the standard of living that
            you’d like in retirement, giving a level of anticipated retirement
            spending. The ‘retirement goal’ shown as the dotted line in the
            infographic shows the size of retirement savings that might be
            needed to provide for this level of retirement spending (though this
            is not guaranteed), based on a number of assumptions that we’ve
            detailed below, and after a full state pension <br></br> (two full
            state pensions if a couple). That means that we’ve assumed that a
            full state pension covers the relevant portion of retirement
            spending, and that a retirement savings pot is required to cover the
            remainder.
          </div>
          <br></br>
          <div>
            Pension contributions benefit from tax relief at your marginal rate
            of tax. You can see the impact of pension tax relief in the
            ‘pensions’ line (in green) – the difference between this line and
            the ‘investments’ line (in blue) is due to the pension contribution
            tax relief.
          </div>
          <h2>Assumptions</h2>
          This infographic is for illustrative purposes only, and is based on a
          number of assumptions for the customer, namely:
          <ul>
            <li>A default retirement age of 68;</li>
            <li>
              A drawdown rate of 3.5% from the retirement savings pot (i.e. 3.5%
              drawn from the retirement savings pot each year, to cover
              retirement spending);
            </li>
            <li>Full state pension from age 68;</li>
            <li>Inflation at 2%;</li>
            <li>Bank deposit interest of 1%;</li>
            <li>
              Investment gross growth rate of 6% (before inflation / investment
              fees);
            </li>
            <li>A fixed level of monthly contributions over time.</li>
          </ul>
          <i>
            Our assumptions and data are based on current legislation and
            taxation, which are subject to change. With investment, your capital
            is at risk, and investments can go up and down. Pension rules apply.
          </i>
        </div>
      }
    >
      <div className="mainChartsContainer">
        <div className="retirmentBox retirementFactors">
          <Typography variant="h5" className="retirementFactorsHeader">
            Retirement inputs
          </Typography>
          <div className="retirementFactorsDivider">
            <svg
              width="286"
              height="2"
              viewBox="0 0 286 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="1"
                x2="285"
                y2="1"
                stroke="#DEDEDE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="startValue">
            <Typography variant="body1" className="startValueHeader">
              Start value
            </Typography>
            <Typography variant="body2" className="startValueUnit">
              £
            </Typography>
            <input
              value={startValue}
              style={{
                color: getTenantConfig(["AppSettings", "textPrimaryColor"]),
              }}
              type="number"
              className="startValueValue"
              onChange={(event) => {
                handleStartValueChange(event);
              }}
            />
          </div>
          <div className="startValue">
            <Typography variant="body1" className="startValueHeader">
              Net monthly contributions
            </Typography>
            <Typography variant="body2" className="netMonthlyContributionsUnit">
              £
            </Typography>
            <input
              value={netMonthlyContributions}
              style={{
                color: getTenantConfig(["AppSettings", "textPrimaryColor"]),
              }}
              type="number"
              className="startValueValue"
              onChange={(event) => {
                handleNetMonthlyContributionsChange(event);
              }}
            />
          </div>
          <div className="grossMonthlyContributions">
            <Typography
              variant="subtitle1"
              className="grossMonthlyContributionsHeader"
            >
              Gross Monthly Contributions (with pension tax relief)
              <Typography
                variant="subtitle1"
                className="grossMonthlyContributionsValue"
              >
                £{getGMC()}
              </Typography>
            </Typography>
          </div>
          <div className="startValue selectTaxRate ">
            <div className="taxRateHeaderContainer">
              <Typography variant="body1" className="selectTaxRateHeader">
                Select your income tax rate
              </Typography>
              <div
                className={`taxRatesDropdownIcon ${
                  showTaxRates === true ? "dropdownIsOpen" : ""
                }`}
                onClick={handleShowTaxRates}
              >
                <AmmoniteDownArrow />
              </div>
            </div>

            {showTaxRates && (
              <div className="taxButtons">
                {/* Work Switches start */}
                <div className="tax">
                  {taxRate === 0.2 ? taxRateFilled : emptyBasicTaxRate}{" "}
                  <Typography
                    variant="body1"
                    className={`taxText ${taxRate === 0.2 ? "taxBold" : ""}`}
                  >
                    Basic rate
                  </Typography>
                </div>
                <div className="tax">
                  {taxRate === 0.4 ? taxRateFilled : emptyHigherTaxRate}{" "}
                  <Typography
                    variant="body1"
                    className={`taxText ${taxRate === 0.4 ? "taxBold" : ""}`}
                  >
                    Higher rate
                  </Typography>
                </div>
                <div className="tax">
                  {taxRate === 0.45 ? taxRateFilled : emptyAdditionalTaxRate}{" "}
                  <Typography
                    variant="body1"
                    className={`taxText ${taxRate === 0.45 ? "taxBold" : ""}`}
                  >
                    Additional rate
                  </Typography>
                </div>
                {/* Work Switches end */}
              </div>
            )}
          </div>
          <div className="retirementFactorsDivider">
            <svg
              width="286"
              height="2"
              viewBox="0 0 286 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="1"
                y1="1"
                x2="285"
                y2="1"
                stroke="#DEDEDE"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {/* <div className="chargeFees">
            <div className="taxRateHeaderContainer">
              <Typography variant="body1" className="selectTaxRateHeader">
                Total annual fees
              </Typography>
              <div
                className={`chargeFeesDropdownIcon ${
                  showFees === true ? "dropdownIsOpen" : ""
                }`}
                onClick={handleShowFees}
              >
                <AmmoniteDownArrow />
              </div>
            </div>
            {showFees && (
              <div className="taxButtons">
                <div className="tax">
                  {newFees === 1.25 ? taxRateFilled : emptyAmmoniteFees}{" "}
                  <Typography
                    variant="body1"
                    className={`taxText ${newFees === 1.25 ? "taxBold" : ""}`}
                  >
                    Ammonite Wealth: circa 1.25%
                  </Typography>
                </div>
                <div className="tax">
                  {newFees === 2.5 ? taxRateFilled : emptyIFAFees}{" "}
                  <Typography
                    variant="body1"
                    className={`taxText ${newFees === 2.5 ? "taxBold" : ""}`}
                  >
                    Traditional IFA: circa 2.50%
                  </Typography>
                </div>
              </div> 
            )}
          </div>*/}
        </div>
        {data && (
          <div className="retirmentBox retirementChart">
            <GraphHeader
              className="graphHeader"
              pensionsAmount={
                data ? data.datasets[0].data[yearsToRetirement] : 0
              }
              investmentAmount={
                data ? data.datasets[1].data[yearsToRetirement] : 0
              }
              cashAmount={data ? data.datasets[2].data[yearsToRetirement] : 0}
              retirementGoalAmount={data ? data.datasets[3].data[0] : 0}
            />
            <Line ref={chartEl} data={data} options={options} redraw />
          </div>
        )}
      </div>
      <div className="chartsWarningIcon">
        <AmmoniteInformation />
      </div>
      <div className="chartsWarningText">
        With investment, your capital is at risk, and investments can go up and
        down. Pension rules apply.
      </div>
    </Page>
  );
}

export default Charts;
