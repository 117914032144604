import React from "react";

import { getTenantConfig } from "../../Utils/data/getTenantConfig";

function AmmoniteMaleAvatar() {
  return (
    <svg
      title="male-avatar"
      width="84"
      height="84"
      viewBox="0 0 84 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M84 42C84.0075 47.6855 82.8567 53.3129 80.6176 58.539L80.4785 58.8596C76.6911 67.4757 70.1239 74.5729 61.827 79.0163C53.5302 83.4597 43.983 84.9927 34.7121 83.3699C34.4599 83.3252 34.208 83.2788 33.9566 83.2308C31.3587 82.7271 28.8148 81.9769 26.3594 80.9903C25.9605 80.83 25.5645 80.6639 25.1714 80.4921C24.903 80.3753 24.6346 80.2547 24.3687 80.1305C17.0944 76.759 10.9355 71.3783 6.61802 64.6226C2.30049 57.8668 0.0042704 50.0175 0 42C0 18.8043 18.8043 0 42 0C65.1957 0 84 18.8043 84 42Z"
        fill={getTenantConfig(["AppSettings", "checkboxColour"])}
      />
      <path
        opacity="0.2"
        d="M34.712 83.3698C34.4598 83.325 34.2079 83.2786 33.9565 83.2306C33.7925 78.4702 33.2296 68.6996 31.3098 58.4668C30.2598 52.8664 28.8022 47.1255 26.7805 41.9936C26.0878 40.2179 25.2967 38.4822 24.4108 36.7945C22.368 32.9363 19.8902 29.6881 16.8856 27.4874L17.328 26.8835C19.8269 28.7151 22.0727 31.2865 24.0654 34.5976C24.6631 35.5892 25.2372 36.6471 25.7876 37.7712C26.3779 38.9741 26.9408 40.251 27.4763 41.6022C27.8181 42.4621 28.1482 43.3518 28.4667 44.2713C29.8534 48.2638 31.0323 52.8191 32.0031 57.9374C32.2181 59.0682 32.4227 60.2267 32.617 61.413C34.1218 70.6045 34.5753 79.1027 34.712 83.3698Z"
        fill={getTenantConfig(["AppSettings", "textPrimaryColor"])}
      />
      <path
        opacity="0.2"
        d="M25.1713 80.4921C24.9029 80.3753 24.6345 80.2547 24.3686 80.1305C24.1089 77.3098 23.6317 73.8106 22.7594 70.4891C22.3774 68.9974 21.8869 67.5355 21.2919 66.1152C20.2966 63.7853 19.008 61.8046 17.3417 60.5831L17.784 59.9805C19.5287 61.2579 20.8744 63.2771 21.9144 65.6393C23.0862 68.3009 23.8678 71.3987 24.3885 74.3586C24.7749 76.5568 25.0185 78.6804 25.1713 80.4921Z"
        fill={getTenantConfig(["AppSettings", "textPrimaryColor"])}
      />
      <path
        opacity="0.2"
        d="M12.7071 27.4703C14.772 27.4703 16.446 25.7964 16.446 23.7315C16.446 21.6666 14.772 19.9927 12.7071 19.9927C10.6422 19.9927 8.96832 21.6666 8.96832 23.7315C8.96832 25.7964 10.6422 27.4703 12.7071 27.4703Z"
        fill={getTenantConfig(["AppSettings", "textPrimaryColor"])}
      />
      <path
        opacity="0.2"
        d="M13.9111 59.6548C15.976 59.6548 17.6499 57.9809 17.6499 55.916C17.6499 53.8512 15.976 52.1772 13.9111 52.1772C11.8462 52.1772 10.1723 53.8512 10.1723 55.916C10.1723 57.9809 11.8462 59.6548 13.9111 59.6548Z"
        fill={getTenantConfig(["AppSettings", "textPrimaryColor"])}
      />
      <path
        opacity="0.2"
        d="M15.1247 40.6296C19.3912 42.1897 23.6784 41.1886 23.6784 41.1886C23.6784 41.1886 21.0483 37.658 16.7818 36.0979C12.5154 34.5378 8.22815 35.539 8.22815 35.539C8.22815 35.539 10.8583 39.0695 15.1247 40.6296Z"
        fill={getTenantConfig(["AppSettings", "textPrimaryColor"])}
      />
      <path
        opacity="0.2"
        d="M14.4011 69.1301C17.3915 70.2236 20.4007 69.5103 20.4007 69.5103C20.4007 69.5103 18.5615 67.024 15.571 65.9305C12.5806 64.837 9.57135 65.5504 9.57135 65.5504C9.57135 65.5504 11.4106 68.0366 14.4011 69.1301Z"
        fill={getTenantConfig(["AppSettings", "textPrimaryColor"])}
      />
      <path
        d="M41.9138 51.3008C50.9568 51.3008 58.2876 43.97 58.2876 34.927C58.2876 25.884 50.9568 18.5532 41.9138 18.5532C32.8708 18.5532 25.54 25.884 25.54 34.927C25.54 43.97 32.8708 51.3008 41.9138 51.3008Z"
        fill="#F5F8FA"
      />
      <path
        d="M35.5176 47.4632C35.5176 47.4632 37.5643 57.1852 37.5643 58.2086C37.5643 59.2319 47.2862 63.8371 47.2862 63.8371L55.9848 62.302L59.0549 53.0917C59.0549 53.0917 53.9381 45.4165 53.9381 42.3464L35.5176 47.4632Z"
        fill="#F5F8FA"
      />
      <path
        d="M80.6176 58.5391L80.4784 58.8597C76.691 67.4758 70.1238 74.573 61.827 79.0164C53.5301 83.4598 43.9829 84.9927 34.7121 83.37C34.4598 83.3253 34.208 83.2789 33.9566 83.2308C31.3587 82.7272 28.8148 81.977 26.3593 80.9904C25.9604 80.8301 25.5645 80.664 25.1714 80.4921C24.903 80.3753 24.6346 80.2548 24.3686 80.1305C21.9583 79.0139 19.6596 77.6708 17.5033 76.1194L15.5623 70.4892L16.6533 69.6567L18.8975 67.9431L21.292 66.1153L21.9145 65.6394L31.3098 58.4671L32.0032 57.9377L36.8866 54.2099L36.8879 54.2087C36.8879 54.2087 42.1689 62.8137 49.8445 60.2552C57.5201 57.6967 57.3523 50.3604 57.3523 50.3604L80.6176 58.5391Z"
        fill={getTenantConfig(["AppSettings", "textPrimaryColor"])}
      />
      <path
        d="M27.7146 21.7352C27.7146 21.7352 32.9132 10.4715 42.8772 13.0708C52.8412 15.6701 58.4731 19.5691 58.9063 23.4681C59.3395 27.367 58.6897 33.2155 58.6897 33.2155C58.6897 33.2155 57.6066 25.2009 50.6751 26.9338C43.7437 28.6667 32.9132 27.367 32.9132 27.367L31.1803 42.9629C31.1803 42.9629 29.2308 40.147 27.0648 41.8799C24.8987 43.6127 20.7831 25.2009 27.7146 21.7352Z"
        fill={getTenantConfig(["AppSettings", "textPrimaryColor"])}
      />
      <path
        d="M26.3594 80.9905C25.9605 80.8302 25.5645 80.6641 25.1714 80.4922C24.903 80.3754 24.6346 80.2549 24.3687 80.1306C20.3823 78.2821 16.7099 75.8209 13.4847 72.8365C14.6267 71.2808 15.5624 70.4893 15.5624 70.4893H23.2379L24.3886 74.3587L26.3594 80.9905Z"
        fill={getTenantConfig(["AppSettings", "textPrimaryColor"])}
      />
    </svg>
  );
}

export default AmmoniteMaleAvatar;
