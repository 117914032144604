// Core React dependencies
import React, { useState, useEffect, useRef } from "react";

// Extra React dependencies
import { withRouter } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router";

// External libraries

// Component imports
import { getTenantConfig } from "../../Utils/data/getTenantConfig";
import NewAmmoniteLoginLogo from "../../Assets/SVG/AmmoniteLoginLogo/NewAmmoniteLoginLogo";

// Actions

// Styling
import "./login.css";

function Login() {
  const { push } = useHistory();
  const { loginWithRedirect } = useAuth0();
  const { isAuthenticated } = useAuth0();

  return (
    <>
      {!isAuthenticated && (
        <div
          className="loginContainer"
          style={{
            backgroundColor: getTenantConfig(["Login", "backgroundColor"]),
          }}
        >
          <div className="ammoniteLogoContainer">
            <NewAmmoniteLoginLogo />
          </div>
          <div className="loginCard" onSubmit={() => loginWithRedirect()}>
            <div className="loginHeader">Sign in to your account</div>
            <div className="loginButtonContainer">
              <Button
                variant="contained"
                type="submit"
                style={{
                  width: "123px",
                  height: "46px",
                  borderRadius: "8px",
                  fontFamily: "Brother 1816",
                  fontStyle: "normal",
                  fontWeight: "bold",
                  fontSize: "18px",
                  lineHeight: "26px",
                  letterSpacing: "0.02em",
                  color: getTenantConfig(["Login", "buttonTextColor"]),
                  backgroundColor: getTenantConfig([
                    "Login",
                    "backgroundColor",
                  ]),
                  textTransform: "none",
                }}
                onClick={() => loginWithRedirect()}
              >
                Log in
              </Button>
            </div>
          </div>
          <div className="loginInfoText">
            {getTenantConfig(["AppSettings", "tenantCompanyName"])} is
            authorised and regulated by the Financial Conduct Authority no.
            {getTenantConfig(["AppSettings", "tenantFCA"])}
          </div>
        </div>
      )}
      {isAuthenticated && push("/about")}
    </>
  );
}

export default withRouter(Login);
