import React from "react";

import { Typography } from "@material-ui/core";

import AmmoniteCardMargin from "../../Assets/SVG/goalCardMargin";
import AmmoniteTrashcan from "../../Assets/SVG/trashcan";

import { getTenantConfig } from "../../Utils/data/getTenantConfig";

import "./goalCard.css";

function AmmoniteGoalCard({
  goalType,
  goalName,
  showDelete,
  handleDelete,
  handleNameChange,
}) {
  const colourTypes = {
    "Retirement pot": "#BF802F",
    Property: " #247BA0",
    Family: " #3F762C",
    Education: " #69306D",
    "Long Term Investment": " #373986",
    "Care Fees": "#0C3957",
    Other: " #F25F5C",
  };

  const handleDeleteClick = () => {
    handleDelete();
  };

  const handleCardNameChange = (value) => {
    handleNameChange(value);
  };

  return (
    <div className="goalCardMainContainer">
      <AmmoniteCardMargin colour={colourTypes[goalType]} height="163" />
      <div className="cardBody">
        <div className="cardHeader">
          <Typography variant="h5" className="goalTypeContainer">
            {goalType}
          </Typography>

          {showDelete && (
            <div className="deleteContainer">
              <div className="deleteIcon" onClick={() => handleDeleteClick()}>
                <AmmoniteTrashcan colour="#7A7A7A" />
              </div>

              <div className="deleteText">Delete</div>
            </div>
          )}
        </div>
        <input
          type="text"
          placeholder="Give your goal a name"
          value={goalName}
          style={{
            color: getTenantConfig(["AppSettings", "textPrimaryColor"]),
          }}
          className="goalText"
          onChange={(event) => handleCardNameChange(event.target.value)}
        />
      </div>
    </div>
  );
}

export default AmmoniteGoalCard;
