import React from "react";
import { Typography } from "@material-ui/core";
import "./graphHeader.css";

function GraphHeader({
  pensionsAmount,
  investmentAmount,
  cashAmount,
  retirementGoalAmount,
}) {
  return (
    <div className="graphInfo">
      <Typography variant="h5" className="graphHeaderItem">
        Pensions
      </Typography>
      <div className="graphHeaderItem pensionsGraphAmount">
        £{!isNaN(pensionsAmount) ? Math.floor(pensionsAmount) : 0}
      </div>
      <Typography variant="h5" className="graphHeaderItem">
        Investment
      </Typography>
      <div className="graphHeaderItem investmentGraphAmount">
        £{!isNaN(investmentAmount) ? Math.floor(investmentAmount) : 0}
      </div>
      <Typography variant="h5" className="graphHeaderItem">
        Cash
      </Typography>
      <div className="graphHeaderItem cashGraphAmount">
        £{!isNaN(cashAmount) ? Math.floor(cashAmount) : 0}
      </div>
      <Typography variant="subtitle1" className="retirementGoalGraphHeader">
        <div className="retirementGraphHeader">Retirement Goal</div>
        <div className="graphHeaderItem">
          £{Math.floor(retirementGoalAmount)}
        </div>
      </Typography>
    </div>
  );
}

export default GraphHeader;
