import React, { useState, useEffect, useRef } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuth0();
  const loaded = useRef(false);
  const { isAuthenticated } = useAuth0();
  const domain = process.env.REACT_APP_AUTH_ZERO_NAMESPACE;
  const [isAuthorized, setIsAuthorized] = useState(false);
  useEffect(() => {
    if (!user || user === null) {
      console.log("USER", user);
      return;
    }

    console.log("USEEFFECT HEADER", isAuthorized.current);

    async function checkUserRoles() {
      console.log("CALLED!", user);
      const userDomain = `${domain}/roles`;
      const userRoles = user[userDomain];

      let _hasACorrectRole = () => {
        const requiredRoles = ["admin", "coach"];
        let _hasrole = false;
        for (let role of requiredRoles) {
          console.log("ROLE!", role, userRoles);
          if (userRoles.includes(role)) return (_hasrole = true);
        }
        console.log(_hasrole);
        return _hasrole;
      };

      if (_hasACorrectRole()) {
        console.log("_hasACorrectRole", _hasACorrectRole());
        setIsAuthorized(true);
      }
    }

    loaded.current = true;
    checkUserRoles();
    console.log("isAuthorized", isAuthorized, loaded.current);
  }, [user, isAuthenticated]);

  return (
    <Route>{!isAuthorized ? <>UNAUTHORISED</> : <Component {...rest} />}</Route>
  );
};

export default ProtectedRoute;
