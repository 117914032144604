import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./responsive.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import "./wdyr";

var uri = "http://localhost:3000";

if (process.env.NODE_ENV === "production") {
  uri = process.env.REACT_APP_URI;
}
const domain = process.env.REACT_APP_AUTH_ZERO_DOMAIN;
const client_id = process.env.REACT_APP_AUTH_ZERO_CLIENT_ID;

const providerConfig = {
  domain: domain,
  clientId: client_id,
  redirectUri: uri,
};

ReactDOM.render(
  <Auth0Provider {...providerConfig}>
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
