import React, { useState } from "react";

//Utils
import { getTenantConfig } from "../../Utils/data/getTenantConfig";

// External Libraries
import Button from "@material-ui/core/Button";

import "./CookieBanner.css";

function CookieBanner({ closeDialog }) {
  const [showManage, setShowManage] = useState(false);
  const [analytics, setAnalytics] = useState(false);

  let cookies = [
    {
      type: "Essential",
      aboutText: (
        <div>
          Essential cookies are necessary for the website to function. They are
          used for tasks such as when you set your preferences, when filling
          forms or for accessing secure areas of the website. You can set your
          browser to remove or reject these, but it will result in some parts of
          the website not working. Additional cookies may be placed on your
          computer by our third party service provider Hubspot. These cookies
          allow Hubspot to gather and track information about you. These cookies
          can be manually disabled in your browser. <br></br>
          <br></br>
          Additional cookies may be placed on your computer by our third party
          service providers Firebase and Algolia. These cookies allow these
          service providers to gather and track information about you.
        </div>
      ),
      optional: false,
    },
  ];

  const cookieType = (cookie) => {
    return (
      <div className="cookieType">
        <div className="cookieCheckbox">
          <div className="cookieCheckboxLabel">
            <label>{`${cookie.type} Cookies`}</label>
          </div>
          {cookie.optional && (
            <input
              type="checkbox"
              id="analytics"
              name="analytics"
              onChange={handleChecked}
              checked={handleCheckedState(cookie.type)}
            />
          )}
        </div>
        <div className="cookieAboutText">{cookie.aboutText}</div>
      </div>
    );
  };

  const handleCheckedState = (type) => {
    switch (type) {
      case "Analytics":
        return analytics;
      default:
        return true;
    }
  };

  function handleChecked(event) {
    if (event.target.name === "analytics") {
      setAnalytics(event.target.checked);
    }
  }

  const acceptAllClicked = (event) => {
    document.cookie = "_acpt=1; max-age=21600; ";
    closeDialog(event);
  };

  return (
    <div className="cookieBannerBlocker">
      <div className="cookieBannerContainer">
        {!showManage && (
          <div className="cookieText">
            We use cookies to provide you with a better experience on our
            website. Please let us know your preferences. For more information
            check out our{" "}
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ammonite-website.appspot.com/o/pdfs%2FLegal%2FPrivacy%20Notice.pdf?alt=media&token=1aaae7f7-a8b9-48ea-b824-691dff1882c3"
              target="_blank"
              rel="noreferrer"
            >
              Privacy Policy
            </a>{" "}
            and{" "}
            <a
              href="https://firebasestorage.googleapis.com/v0/b/ammonite-website.appspot.com/o/pdfs%2FLegal%2Fammonite%20Cookie%20Policy.pdf?alt=media&token=838586b9-5854-453c-864a-908c8fe88e4d"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Policy
            </a>
          </div>
        )}
        {showManage &&
          cookies &&
          cookies.map((cookie) => {
            return cookieType(cookie);
          })}
        <div className="cookieButtons">
          <div
            className="cookieAccept"
            onClick={(event) => {
              acceptAllClicked(event);
            }}
          >
            <Button
              variant="contained"
              style={{
                width: "143px",
                height: "60px",
                borderRadius: "8px",
                fontFamily: "Brother 1816",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "18px",
                lineHeight: "26px",
                letterSpacing: "0.02em",
                color: getTenantConfig(["Login", "buttonTextColor"]),
                backgroundColor: getTenantConfig(["Login", "backgroundColor"]),
                textTransform: "none",
              }}
            >
              Accept All
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookieBanner;
