import React from "react";

//External libraries
import { useLocation } from "react-router";
import { Typography } from "@material-ui/core";

//Assets
import AmmoniteMenuLine from "../../Assets/SVG/menuLine";
import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilled from "../../Assets/SVG/menuCircleFilled";
import AmmoniteTickSVG from "../../Assets/SVG/tick";

//Utils
import { getTenantConfig } from "../../Utils/data/getTenantConfig";

//Styling
import "./menuButton.css";

function AmmoniteMenuButton({
  route,
  endButton,
  buttonText,
  handleCloseMenuAndReroute,
}) {
  const location = useLocation();
  const clickHandler = (route) => {
    handleCloseMenuAndReroute(route);
  };
  return (
    <div className="buttonGroup">
      <div className="buttonLabelGroup">
        {route !== location.pathname ? (
          <div
            className="buttonHandlerContainer"
            onClick={() => clickHandler(route)}
          >
            <AmmoniteMenuCircleEmpty
              className="circleSVG"
              colour={getTenantConfig(["AppSettings", "checkboxColour"])}
              width="36px"
              height="36px"
              cx="18px"
              cy="18px"
              r="14px"
            />
          </div>
        ) : (
          <div className="buttonHandlerContainer">
            <div className="tickSVG">
              <AmmoniteTickSVG
                colour="white"
                width="22"
                height="18"
                transx="0"
                transy="0"
                scale="1"
              />
            </div>
            <div className="circleSVG">
              <AmmoniteMenuCircleFilled
                colour={getTenantConfig(["AppSettings", "checkboxColour"])}
                width="36px"
                height="36px"
                cx="18px"
                cy="18px"
                r="14px"
              />
            </div>
          </div>
        )}
        <div className="buttonLabel">
          <Typography
            color="textSecondary"
            align="inherit"
            fontWeight="fontWeightBold"
          >
            {buttonText}
          </Typography>
        </div>
      </div>
      {!endButton && (
        <AmmoniteMenuLine
          className="lineSVG"
          colour={getTenantConfig(["AppSettings", "checkboxColour"])}
        />
      )}
    </div>
  );
}

export default AmmoniteMenuButton;
