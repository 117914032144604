import React from "react";
import AmmoniteTickSVG from "../../Assets/SVG/tick";

import "./checkbox.css";

import { getTenantConfig } from "../../Utils/data/getTenantConfig";

//External Libraries
import { Typography } from "@material-ui/core";

function AmmoniteCheckbox({
  isBold,
  checked,
  checkedHandler,
  checkboxText,
  inlineText,
  scale,
}) {
  const clickHandler = () => {
    checkedHandler();
  };
  return (
    <div className={`checkboxContainer ${scale === true ? "halfSize" : ""}`}>
      {checked ? (
        <div className="checkboxChecked" onClick={clickHandler}>
          <svg
            width="36"
            height="36"
            viewBox="0 0 36 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="2"
              y="2"
              width="32"
              height="32"
              rx="6"
              fill={getTenantConfig(["AppSettings", "filledCheckboxColour"])}
              stroke={getTenantConfig(["AppSettings", "checkboxColour"])}
              strokeWidth="4"
            />
            <path
              d="M11.6073 17.3778C11.412 17.1825 11.0954 17.1825 10.9002 17.3778L9.64645 18.6315C9.55268 18.7253 9.5 18.8525 9.5 18.9851C9.5 19.1177 9.55268 19.2449 9.64645 19.3386L14.6614 24.3536C14.8566 24.5488 15.1732 24.5488 15.3685 24.3536L26.1147 13.6073C26.2085 13.5135 26.2612 13.3863 26.2612 13.2537C26.2612 13.1211 26.2085 12.9939 26.1147 12.9002L24.861 11.6464C24.6658 11.4512 24.3492 11.4512 24.1539 11.6464L15.0149 20.7854L11.6073 17.3778Z"
              fill="white"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>{" "}
        </div>
      ) : (
        <div
          className="checkboxUnchecked"
          style={{
            border: `4px solid ${getTenantConfig([
              "AppSettings",
              "checkboxColour",
            ])}`,
          }}
          onClick={clickHandler}
        />
      )}
      <Typography
        variant="body2"
        className={`checkboxText ${isBold === true ? "isBold" : ""} ${
          inlineText === true ? "isInline" : ""
        }`}
      >
        {checkboxText}
      </Typography>
    </div>
  );
}

export default AmmoniteCheckbox;
