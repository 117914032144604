import React from "react";
// import AmmoniteTickSVG from '../../Assets/SVG/tick';

import { getTenantConfig } from "../../Utils/data/getTenantConfig";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";

import "./dropdown.css";

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    width: 150,
    border: "1px solid #ced4da",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: "Brother 1816",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    color: getTenantConfig(["AppSettings", "textPrimaryColor"]),
    "&:focus": {
      borderRadius: 4,
      borderColor: getTenantConfig(["AppSettings", "checkboxColour"]),
      boxShadow: `0 0 0 0.2rem ${getTenantConfig([
        "AppSettings",
        "checkboxColour",
      ])}40`,
    },
  },
}))(InputBase);

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontFamily: "Brother 1816",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "26px",
    display: "flex",
    alignItems: "center",
    color: getTenantConfig(["AppSettings", "textPrimaryColor"]),
    "&:focus": {
      backgroundColor: getTenantConfig(["AppSettings", "checkboxColour"]),
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "blue",
      },
    },
    "&:hover": {
      backgroundColor: getTenantConfig(["AppSettings", "filledCheckboxColour"]),
    },
    "&:before": {
      backgroundColor: "pink",
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "blue",
      },
    },
  },
}))(MenuItem);

const useStyles = makeStyles(() => ({
  icon: {
    position: "relative",
    top: 0,
    left: -40,
    fill: getTenantConfig(["AppSettings", "checkboxColour"]),
    padding: 0,
    fontSize: "3em",
  },
  active: {
    backgroundColor: "pink",
  },
}));

function AmmoniteDropdown({
  options,
  selected,
  handleDropdownChange,
  isDown,
  disabled = false,
  ...other
}) {
  const classes = useStyles();

  return (
    <div className="dropdownContainer">
      <FormControl>
        <Select
          id="select"
          value={selected}
          onChange={handleDropdownChange}
          input={<BootstrapInput />}
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
          disabled={disabled}
        >
          {options &&
            options.map((option, index) => (
              <StyledMenuItem
                key={index}
                value={option.value}
                classes={{ selected: classes.active }}
              >
                {option.text}
              </StyledMenuItem>
            ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default AmmoniteDropdown;
