// Core React dependencies
import React, { useState, useEffect, useContext } from "react";

//External libraries
import { Typography } from "@material-ui/core";
import Loader from "react-loader-spinner";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

//Assets imports
import AmmoniteHiFive from "../../Assets/SVG/Illustrations/HiFive/HiFive";

// Component imports
import AmmoniteCovered from "../../components/Covered/covered";
import AmmoniteCheckbox from "../../components/Checkbox/Checkbox";
import AmmoniteSortOut from "../../components/SortOut/sortOut";
import AmmoniteToast from "../../components/Toast/toast";

//Container imports
import Page from "../../containers/Page/page";

//Utils imports
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getTenantConfig } from "../../Utils/data/getTenantConfig";

// Actions
import {
  refreshContextFromCache,
  setPensionsCovered,
  setMortgageCovered,
  setSpeakToPensionsAdvisorAbout,
  setSpeakToMortgageAndProtectionAdvisorAbout,
  setMortgageAPriority,
  setPensionsAPriority,
} from "../../Utils/reducers/actions";

// Styling
import "./actions.css";

function Actions() {
  const { user } = useAuth0();
  console.log(user);

  const context = useContext(AmmoniteContext);
  const [financialHealth, setFinancialHealth] = useState(
    context.state.financialCheck
  );
  const dispatch = context.dispatch;
  const [pAndI, setPAndI] = useState(false);
  const [showTop, setShowTop] = useState(null);
  const [mortgage, setMortgage] = useState(false);
  const [coveredItems, setCoveredItems] = useState([
    { will: { text: "a Will", status: financialHealth.will } },
    {
      mortgage: {
        text: "Mortgage",
        status:
          financialHealth.hasMortgage &&
          financialHealth.timeUntilMortgageDealEnds < 2,
      },
    },
    {
      knowsStatePensionAge: {
        text: "Knows state pension age",
        status: !context.state.financialCheck.knowsStatePensionAge,
      },
    },
    {
      pensions: {
        text: "Review your pensions",
        status: context.state.prevPensions,
      },
    },
    { lifeCover: { text: "Life Cover", status: false } },
    { incomeProtection: { text: "Income protection", status: false } },
    {
      environmentPriority: {
        text: "Invest environmentally",
        status: context.state.financialCheck.environmentPriority,
      },
    },
    {
      emergencyCash: {
        text: "Emergency cash",
        status: context.state.financialCheck.emergencyCash,
      },
    },
    {
      shortTermDebt: {
        text: "Budgeting",
        status: !context.state.financialCheck.shortTermDebt,
      },
    },
  ]);
  const [coveredMap, setCoveredMap] = useState();

  const getIsCovered = (item) => {
    if (item[Object.keys(item)].status === true) {
      return <AmmoniteCovered coveredItemText={item[Object.keys(item)].text} />;
    }
  };

  const getShowPAndI = () => {
    if (
      financialHealth.will === true ||
      financialHealth.prevPensions === true ||
      financialHealth.knowsStatePensionAge === false ||
      financialHealth.shortTermDebt === true ||
      financialHealth.emergencyCash === false ||
      financialHealth.environmentPriority === false
    ) {
      return true;
    }
    return false;
  };

  const getShowMortgage = () => {
    if (
      (financialHealth.hasMortgage === true &&
        financialHealth.timeUntilMortgageDealEnds < 2) ||
      financialHealth.lifeCover === false ||
      financialHealth.incomeProtection === true
    ) {
      return true;
    }
    return false;
  };

  const pandiHandler = () => {
    if (pAndI === false) {
      showToastHandler("pAndI");
    } else {
      showToastHandler(null);
    }
    setPAndI(!pAndI);
    dispatch({
      type: setPensionsAPriority,
    });
  };

  const mortgageHandler = () => {
    if (mortgage === false) {
      showToastHandler("mortgage");
    } else {
      showToastHandler(null);
    }
    setMortgage(!mortgage);
    dispatch({
      type: setMortgageAPriority,
    });
  };

  const getNewCoveredItems = () => {
    let _tempCoveredItems = coveredItems;
    if (pAndI === true) {
      //Defines if something goes to well done when Pension/Investment planning is checked
      _tempCoveredItems[0].will.status = true;
      _tempCoveredItems[2].knowsStatePensionAge.status = true;
      _tempCoveredItems[3].pensions.status =
        context.state.financialCheck.prevPensions;
      _tempCoveredItems[6].environmentPriority.status =
        context.state.financialCheck.environmentPriority;
      _tempCoveredItems[7].emergencyCash.status = true;
      _tempCoveredItems[8].shortTermDebt.status =
        context.state.financialCheck.shortTermDebt;
    } else {
      //Defines if something the user ticked/not ticked on Financial Health Check Page should appear on well done
      _tempCoveredItems[0].will.status = context.state.financialCheck.will;
      _tempCoveredItems[2].knowsStatePensionAge.status =
        context.state.financialCheck.knowsStatePensionAge;
      _tempCoveredItems[3].pensions.status = false;
      _tempCoveredItems[6].environmentPriority.status = false;
      _tempCoveredItems[7].emergencyCash.status =
        context.state.financialCheck.emergencyCash;
      _tempCoveredItems[8].shortTermDebt.status = false;
    }

    if (mortgage === true) {
      //Defines if something goes to well done when Mortgage/Protection planning is checked
      _tempCoveredItems[1].mortgage.status =
        financialHealth.hasMortgage &&
        financialHealth.timeUntilMortgageDealEnds < 2;
      _tempCoveredItems[4].lifeCover.status = true;
      _tempCoveredItems[5].incomeProtection.status = true;
    } else {
      //Defines if something the user ticked/not ticked on Financial Health Check Page should appear on well done
      _tempCoveredItems[1].mortgage.status = false;
      _tempCoveredItems[4].lifeCover.status =
        context.state.financialCheck.lifeCover;
      _tempCoveredItems[5].incomeProtection.status =
        context.state.financialCheck.incomeProtection;
    }

    setCoveredItems(_tempCoveredItems);
  };

  const getCoveredMap = () => {
    setCoveredMap(coveredItems.map((item, index) => getIsCovered(item)));
  };

  const showToastHandler = (value) => {
    setShowTop(value);
  };

  useEffect(() => {
    if (context.state.clean === true && localStorage.ammoniteContext) {
      dispatch({
        type: refreshContextFromCache,
        payload: JSON.parse(localStorage.getItem("ammoniteContext")),
      });
      setFinancialHealth(
        JSON.parse(localStorage.getItem("ammoniteContext")).state.financialCheck
      );
    }
    getNewCoveredItems();
    if (pAndI) {
      const pAndIAdviceAbout = [];
      if (financialHealth.will === false) {
        pAndIAdviceAbout.push("Write a will");
      }
      if (financialHealth.prevPensions === true) {
        pAndIAdviceAbout.push("Pensions");
      }
      if (financialHealth.knowsStatePensionAge === false) {
        pAndIAdviceAbout.push("State Pension Age");
      }
      if (financialHealth.shortTermDebt === true) {
        pAndIAdviceAbout.push("Short Term Debt");
      }
      dispatch({
        type: setSpeakToPensionsAdvisorAbout,
        payload: pAndIAdviceAbout,
      });
    }
    if (mortgage) {
      const mortgageAdviceAbout = [];
      if (financialHealth.lifeCover === false) {
        mortgageAdviceAbout.push("Life Cover");
      }
      if (context.state.covered.mortgage === false) {
        mortgageAdviceAbout.push("Mortgage");
      }
      if (financialHealth.incomeProtection === false) {
        mortgageAdviceAbout.push("Income Protection");
      }
      dispatch({
        type: setSpeakToMortgageAndProtectionAdvisorAbout,
        payload: mortgageAdviceAbout,
      });
    }
    if (coveredItems) {
      getCoveredMap();
    }
    dispatch({
      type: setPensionsCovered,
      payload: { advice: pAndI },
    });
    dispatch({
      type: setMortgageCovered,
      payload: { advice: mortgage },
    });
  }, [pAndI, mortgage, coveredItems, showTop, financialHealth]);

  return (
    <Page
      progress={1}
      headerText={"Your financial health check results"}
      startOver
      isValid={true}
      backLink="/yourgoalsummary"
      forwardLink="/thankyou"
    >
      {pAndI && mortgage && (
        <div className="summaryTextContainer">
          <Typography className="summaryText">Well done!</Typography>
          <Typography className="summaryText">
            You are off to a great start on your
          </Typography>
          <Typography className="summaryText">
            journey to achieve your goals.
          </Typography>
          <Typography className="summaryTextBold">Let's summarise.</Typography>
        </div>
      )}
      {pAndI && mortgage && (
        <div className="successIllustration">
          <AmmoniteHiFive />
        </div>
      )}
      {showTop === "pAndI" && (
        <div className={`toast ${showTop === "pAndI" ? "displayToast" : ""}`}>
          <AmmoniteToast toastType="pensionAndInvestment" />
        </div>
      )}
      {showTop === "mortgage" && (
        <div
          className={`toast ${showTop === "mortgage" ? "displayToast" : ""}`}
        >
          <AmmoniteToast toastType="mortgageAndProtection" />
        </div>
      )}
      <div className="actionsContainer">
        <div className="actionToDoCard">
          <Typography variant="h5" className="actionCardLabel">
            Well done
          </Typography>
          <div className="actionToDoDivider">
            <svg
              width="289"
              height="4"
              viewBox="0 0 289 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="2"
                y1="2"
                x2="433"
                y2="2"
                stroke={"#3F762C"}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          {coveredItems && <div className="actionsToDo">{coveredMap}</div>}
        </div>
        <div className="actionAmmoniteCard">
          <Typography variant="h5" className="actionCardLabel">
            Ways to improve
          </Typography>
          <div className="actionToDoDivider">
            <svg
              width="460"
              height="4"
              viewBox="0 0 460 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="2"
                y1="2"
                x2="433"
                y2="2"
                stroke={getTenantConfig(["AppSettings", "checkboxColour"])}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="actionsToDo">
            {getShowPAndI && (
              <div className="pandiAdvisor">
                <AmmoniteCheckbox
                  checked={context.state.pensionsAPriority}
                  checkedHandler={pandiHandler}
                  checkboxText="My pension / investment planning is a priority"
                />
                {!pAndI && (
                  <div className="toSortOut">
                    <div className="toSortOutList">
                      {!financialHealth.will && (
                        <AmmoniteSortOut sortOutItemText={"Write a will"} />
                      )}
                      {financialHealth.prevPensions && (
                        <AmmoniteSortOut
                          sortOutItemText={"Review your pensions"}
                        />
                      )}
                      {!financialHealth.knowsStatePensionAge && (
                        <AmmoniteSortOut
                          sortOutItemText={"Know your state pension age"}
                        />
                      )}
                      {financialHealth.shortTermDebt && (
                        <AmmoniteSortOut sortOutItemText={"Budgeting"} />
                      )}
                      {!financialHealth.emergencyCash && (
                        <AmmoniteSortOut sortOutItemText={"Emergency cash"} />
                      )}
                      {financialHealth.environmentPriority && (
                        <AmmoniteSortOut
                          sortOutItemText={"Invest environmentally"}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
            {getShowMortgage && (
              <div className="pandiAdvisor">
                <AmmoniteCheckbox
                  checked={context.state.mortgageAPriority}
                  checkedHandler={mortgageHandler}
                  checkboxText="My mortgage / protection planning is a priority"
                />
                {!mortgage && (
                  <div className="toSortOut">
                    <div className="toSortOutList">
                      {financialHealth.hasMortgage &&
                        financialHealth.timeUntilMortgageDealEnds < 2 && (
                          <AmmoniteSortOut
                            sortOutItemText={"Review your mortgage"}
                          />
                        )}
                      {!financialHealth.lifeCover && (
                        <AmmoniteSortOut sortOutItemText={"Life cover"} />
                      )}
                      {!financialHealth.incomeProtection && (
                        <AmmoniteSortOut
                          sortOutItemText={"Income protection"}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
}

export default withAuthenticationRequired(Actions, {
  onRedirecting: () => <Loader />,
});
