//React
import React, { useState } from "react";

//external libraries
import { Typography } from "@material-ui/core";

//components
import AmmoniteMenuCircleEmpty from "../../Assets/SVG/menuCircleEmpty";
import AmmoniteMenuCircleFilledTicked from "../../Assets/SVG/menuCircleFilledTicked";
import "./roundSwitch.css";
import { getTenantConfig } from "../../Utils/data/getTenantConfig";

function AmmoniteRoundSwitch({ colour, switchText, on, switchHandler }) {
  const [onSwitch, setOnSwitch] = useState(true);

  const handleChange = () => {
    setOnSwitch(!onSwitch);
    switchHandler();
  };

  return (
    <div className="roundSwitchContainer" onClick={handleChange}>
      {on ? (
        <div className="roundSwitch">
          <AmmoniteMenuCircleFilledTicked
            width="36"
            height="36"
            cx="18"
            cy="18"
            r="16"
          />
        </div>
      ) : (
        <div className="roundSwitch">
          <AmmoniteMenuCircleEmpty
            colour={getTenantConfig(["AppSettings", "checkboxColour"])}
            width="36"
            height="36"
            cx="18"
            cy="18"
            r="16"
          />
        </div>
      )}
      <Typography
        variant="body1"
        className={` switchText ${on === true ? "isBold" : ""}`}
      >
        {switchText}
      </Typography>
    </div>
  );
}

export default AmmoniteRoundSwitch;
