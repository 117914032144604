// Core React dependencies
import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loader from "react-loader-spinner";

// Component imports
import AmmoniteStepper from "../../containers/Stepper/Stepper";

// Styling
import "./about.css";

function About() {
  const { user } = useAuth0();
  console.log(user);
  return (
    <AmmoniteStepper
      progress={0}
      headerText=""
      forwardLink="/basicinformation"
    />
  );
}

export default withAuthenticationRequired(About, {
  onRedirecting: () => <Loader />,
});
