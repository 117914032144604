import React, { useState } from "react";
import "./layout.css";

import CookieBanner from "../CookieBanner/CookieBanner";

import { useAuth0 } from "@auth0/auth0-react";

function Layout({ children }) {
  const { user } = useAuth0();
  const auth0RegexMatch = new RegExp(/auth0\.\w*\.is\.authenticated=true/g);

  const cookiePreferenceInitialValue = () => {
    if (
      window.location.pathname !== "/" &&
      window.location.pathname !== "/Login" &&
      document.cookie
        .split(";")
        .some((item) => item.trim().startsWith("_acpt="))
    ) {
      return true;
    } else if (
      user ||
      document.cookie.split(";").some((item) => auth0RegexMatch.test(item))
    ) {
      return true;
    }
    return false;
  };

  const [cookiePreferences, setCookiePreferences] = useState(
    cookiePreferenceInitialValue()
  );

  const closeDialog = (event) => {
    event.preventDefault();
    setCookiePreferences(true);
  };

  return (
    <div className="layoutRoot">
      {cookiePreferences === false && (
        <CookieBanner closeDialog={(event) => closeDialog(event)} />
      )}
      {children}
    </div>
  );
}

export default Layout;
