import React from "react";

import { Typography } from "@material-ui/core";

import "./card.css";

function AmmoniteCard({ image, cardHeader, cardText }) {
  return (
    <div className="cardContainer">
      <Typography variant="h3" color="primary" className="aboutCardHeader">
        {cardHeader}
      </Typography>
      <Typography variant="h5" className="aboutCardText">
        {cardText}
      </Typography>
      <div className="imageContainer">
        <img src={image} alt="About 1" height="440" width="691" />
      </div>
    </div>
  );
}

export default AmmoniteCard;
