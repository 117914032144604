// Core React dependencies
import React, { useState, useEffect, useContext } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

// Extra React dependencies
import { useHistory } from "react-router";

// Component imports
import Loader from "react-loader-spinner";

import Page from "../../containers/Page/page";
import AmmoniteSummaryGoalCard from "../../containers/GoalSummaryCard/GoalSummaryCard";

// Utilities
import AmmoniteContext from "../../Utils/contexts/AmmoniteContext";
import { getTenantConfig } from "../../Utils/data/getTenantConfig";

// Actions
import {
  refreshContextFromCache,
  setCustomerGoals,
  setCustomerGoalsActiveStep,
  setCustomerGoalsActiveStepInflation,
} from "../../Utils/reducers/actions";

// Styling
import "./goalsReview.css";

function GoalsReview() {
  const { user } = useAuth0();
  console.log(user);
  const context = useContext(AmmoniteContext);

  let currentYear = new Date().getFullYear();
  let yearOfBirth = new Date(
    context.state.customerInfo.customers.customer1.customerDoB
  ).getFullYear();
  let yearsToRetirement =
    context.state.customerGoals[0].goalTimeHorizon -
    (currentYear - yearOfBirth);
  if (yearsToRetirement < 0) {
    yearsToRetirement = 0;
  }
  const { push } = useHistory();
  const dispatch = context.dispatch;
  const [goals, setGoals] = useState(context.state.customerGoals);
  const _tempSummaryCards = context.state.customerGoals.slice(1);
  for (var i = 0; i < _tempSummaryCards.length; i++) {
    _tempSummaryCards[i].realIndex = i + 1;
  }
  const goalSummaryCards = _tempSummaryCards
    .sort((a, b) => {
      return a.goalImportance - b.goalImportance;
    })
    .reverse();
  const handleDelete = (realIndex) => {
    const _tempGoals = [...goals];
    _tempGoals.splice(realIndex, 1);
    setGoals(_tempGoals);
  };

  const handleEdit = (realIndex) => {
    dispatch({
      type: setCustomerGoalsActiveStep,
      payload: { activeStep: realIndex },
    });
    setTimeout(() => {
      push("/tellusaboutyourgoals");
    }, 220);
  };

  const handleInflationLinked = (realIndex) => {
    dispatch({
      type: setCustomerGoalsActiveStepInflation,
      payload: { activeStep: realIndex },
    });
  };

  useEffect(() => {
    if (context.state.clean === true && localStorage.ammoniteContext) {
      dispatch({
        type: refreshContextFromCache,
        payload: JSON.parse(localStorage.getItem("ammoniteContext")),
      });
      setGoals(
        JSON.parse(localStorage.getItem("ammoniteContext")).state.customerGoals
      );
    }
    if (goals) {
      dispatch({ type: setCustomerGoals, payload: { goals: goals } });
    }
  }, [goals]);

  return (
    <Page
      headerText={"Your goal summary"}
      introText={" "}
      progress={0.875}
      isValid={true}
      backLink="/savingforretirement"
      forwardLink="/results"
      // reset={resetFurtherInfo}
    >
      <div className="goalSummaryMainContainer">
        <div className={"topRowGoalSummaries"}>
          <div className={"topRowGoalSummariesFirst"}>
            <AmmoniteSummaryGoalCard
              goalType={context.state.customerGoals[0].goalType}
              goalName={context.state.customerGoals[0].goalName}
              inflationLinked={context.state.customerGoals[0].inflationLinked}
              amountRequiredToday={parseInt(
                context.state.customerGoals[0].amountRequiredToday
              )}
              goalAmount={parseInt(context.state.customerGoals[0].goalAmount)}
              goalTimeHorizon={parseInt(yearsToRetirement)}
            />
          </div>
          {
            <div className={"topRowGoalSummariesLast"}>
              {goalSummaryCards.slice(0, 2).map((item, index) => (
                <AmmoniteSummaryGoalCard
                  key={index}
                  inflationLinked={item.inflationLinked}
                  realIndex={item.realIndex}
                  goalType={item.goalType}
                  goalName={item.goalName}
                  amountRequiredToday={parseInt(item.amountRequiredToday)}
                  goalAmount={parseInt(item.goalAmount)}
                  goalTimeHorizon={parseInt(item.goalTimeHorizon)}
                  deleteGoal
                  handleDelete={() => handleDelete(item.realIndex)}
                  handleInflationLinked={() =>
                    handleInflationLinked(item.realIndex)
                  }
                  handleEdit={() => handleEdit(item.realIndex)}
                  editGoal
                />
              ))}
            </div>
          }
        </div>
        <div className={"bottomRowGoalSummaries"}>
          {
            <div className={"topRowGoalSummariesLast"}>
              {goalSummaryCards.slice(2, 6).map((item, index) => (
                <AmmoniteSummaryGoalCard
                  key={index}
                  inflationLinked={item.inflationLinked}
                  realIndex={item.realIndex}
                  goalType={item.goalType}
                  goalName={item.goalName}
                  amountRequiredToday={item.amountRequiredToday}
                  goalAmount={item.goalAmount}
                  goalTimeHorizon={item.goalTimeHorizon}
                  deleteGoal
                  handleDelete={() => handleDelete(item.realIndex)}
                  handleInflationLinked={() =>
                    handleInflationLinked(item.realIndex)
                  }
                  handleEdit={() => handleEdit(item.realIndex)}
                  editGoal
                />
              ))}
            </div>
          }
        </div>
      </div>
      <div className="afterGoalInflation">
        <p
          style={{ color: getTenantConfig(["AppSettings", "checkboxColour"]) }}
          className="asterisk"
        >
          *
        </p>
        After inflation
      </div>
    </Page>
  );
}

export default withAuthenticationRequired(GoalsReview, {
  onRedirecting: () => <Loader />,
});
